const { Dropdowns } = await import("./dropdowns");
const { handleMediaQuery, getBreakpoint, CLASS_IS_VISIBLE } = await import("@/util/layout");
const { getElementBySelector, setElementClass } = await import("@/util/elements");
const { ScrollIndicator } = await import("@/util/scroll-indicator");

const DATA_CONTAINER = "data-breadcrumbs";
const DATA_VIEWPORT = DATA_CONTAINER + "-viewport";
const DATA_CONTENT = DATA_CONTAINER + "-content";
const DATA_SCROLL_INDICATOR = DATA_CONTAINER + "-scroll-indicator-";
const DATA_SCROLL_INDICATOR_LEFT = DATA_SCROLL_INDICATOR + "left";
const DATA_SCROLL_INDICATOR_RIGHT = DATA_SCROLL_INDICATOR + "right";
const PREFIX = "sub-nav";

export class SubNavigation {
    private static instance: SubNavigation;

    public static getInstance(): SubNavigation {
        if (!SubNavigation.instance) {
            SubNavigation.instance = new SubNavigation();
        }

        return SubNavigation.instance;
    }

    private constructor() {
        this.initDropdown();
        this.initBreadcrumbs();
    }

    private initDropdown(): void {
        const dropdowns = Dropdowns.getInstance().dropdownMap;
        const key = Object.keys(dropdowns).find(key => key.startsWith(PREFIX));
        if (key) {
            const dropdown = dropdowns[key];
            // Hide dropdown when changing to view with two columns or more
            const breakpoint = getBreakpoint("sub-nav-one-col-max");
            handleMediaQuery(`(max-width: ${breakpoint})`, (mediaQuery) => {
                if (!mediaQuery.matches) {
                    dropdown.hide();
                }
            });
        }
    }

    private initBreadcrumbs() {
        const breadcrumbs = document.querySelector(`[${DATA_CONTAINER}]`);

        if (breadcrumbs) {
            const scrollIndicators = {
                left: getElementBySelector(`[${DATA_SCROLL_INDICATOR_LEFT}]`, breadcrumbs),
                right: getElementBySelector(`[${DATA_SCROLL_INDICATOR_RIGHT}]`, breadcrumbs),
            };

            Object.keys(scrollIndicators).forEach(key => {
                const direction = key as keyof typeof scrollIndicators;

                const scrollIndicator = new ScrollIndicator({
                    container: breadcrumbs,
                    viewportSelector: `[${DATA_VIEWPORT}]`,
                    contentSelector: `[${DATA_CONTENT}]`,
                    direction,
                });

                scrollIndicator.watch((detached) => {
                    setElementClass(scrollIndicators[direction], CLASS_IS_VISIBLE, detached);
                });
            });
        }
    }
}
